import React, { useState, useCallback, useEffect } from 'react';
import './Input.scss'; // Importation du fichier SCSS
import classNames from 'classnames';
import { useCombobox } from 'downshift';
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import Drawer from '../../Drawer/Drawer';
import PasswordStrength from './PasswordStrength';

const Input = ({className,label,name, required,showPasswordStrength, value, error, full, type, ...props }) => {
  const [showPassword, toggleShowPassword] = useState(false);
  const isPasswordType = type === 'password';
  
  return (
    <div className={classNames("ui-input-wrapper", className)}>
      {label && <label className="ui-input-label" htmlFor={name}>{label}</label>}
      <div className={classNames("ui-input", {error, full})}>
        {!!error && <div className="ui-input-error">{error}</div>}
        <input className={classNames({'has-icon-right':isPasswordType})} type={showPassword ? 'text' : type} name={name} value={value} {...props}/>
        {isPasswordType && <div className="ui-input-icon" onClick={() => toggleShowPassword(!showPassword)}> <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash}/></div>}
      </div>
      {(isPasswordType && showPasswordStrength) && <PasswordStrength password={value} className="ui-input-password-strength"/> }
    </div>
  );
};

export const TextArea = ({className,label,name, required,showPasswordStrength, value, error, full, type, ...props }) => {  
  return (
    <div className={classNames("ui-input-wrapper", className)}>
       <textarea className={classNames("ui-input", {error, full})} name={name} value={value} {...props}/>
    </div>
  );
};


export const SelectInput = ({className,label, onChange, value, error, full, options = [], ...props }) => {
  const [showOptions, toggleShowOptions] = useState(false);
  const handleSelectedOption = (value) => {
    onChange(value);
    toggleShowOptions(false);
  };
  const labelFromValue = options.find(option => option.value === value)?.label || 'Choissisez une option';
  return (
    <>
      <Drawer 
        xsmall
        open={showOptions}
        handleClose={() => toggleShowOptions(false)}
        headContent={options[0]?.label || 'Choissisez une option'}
        >
        <div className="ui-input-select-options">
          {options.map((option, index) => (
            <div key={index} className={classNames("ui-input-select-option", {selected:option.value === value})} onClick={() => handleSelectedOption(option.value)}>
              {option.value === value && <FontAwesomeIcon icon={faCheck} className='ui-input-select-option-icon'/>} {option.label}
            </div>
          ))}
        </div>
      </Drawer>
      <div className={classNames("ui-input-select-wrapper", className)} {...props}>
        {label && <label className="ui-input-label">{label}</label>}
        <div className={classNames("ui-input-select", {error, full})} onClick={()=>toggleShowOptions(true)}>
          {!!error && <div className="ui-input-error">{error}</div>}
          <div className={classNames("ui-input-select-content", {selected:!!value})}>{labelFromValue}</div>
          <div className="ui-input-icon"><FontAwesomeIcon icon={faCaretDown}/></div>
        </div>
      </div>
    </>
  );
}

export const Autocompletion = ({
  fetchSuggestions = ()=>{},
  renderItem,
  onSelect,
  itemToString = (item) => item.label,
  debounceDelay = 100,
  className,
  full,
  defaultValue = "",
  ...props
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [suggestions, setSuggestions] = useState([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchSuggestions = useCallback(
  debounce(async (input) => {
      if (input) {
      const items = await fetchSuggestions(input);
      setSuggestions(items);
      } else {
      setSuggestions([]);
      }
  }, debounceDelay),
  []
  );

  useEffect(() => {
  return () => {
      debouncedFetchSuggestions.cancel();
  };
  }, [debouncedFetchSuggestions]);

  const {
      isOpen,
      getMenuProps,
      getInputProps,
      getItemProps,
      highlightedIndex,
  } = useCombobox({
      inputValue,
      onInputValueChange: ({ inputValue }) => {
          setInputValue(inputValue || '');
          debouncedFetchSuggestions(inputValue);
      },
      itemToString,
      items: suggestions,
      onSelectedItemChange: ({ selectedItem }) => {
          if (onSelect) onSelect(selectedItem);
      }
  });

  return (
    <div className={classNames("ui-autocompletion", className, {full})}>
       <div className={classNames("ui-input", {full})}>
          <input
            {...props}
            {...getInputProps()}
          />
       </div>
      
      <ul
            {...getMenuProps()}
            className="ui-autocompletion-suggestions"
            style={{
              display: (suggestions.length && isOpen) ? 'block' : 'none'
            }}
          >
        {isOpen &&
          suggestions.map((item, index) => (
            <li
                key={item.id || index}
                {...getItemProps({ item, index })}
                className={classNames('ui-autocompletion-suggestion', {
                  'highlighted': highlightedIndex === index,
                })}
            >
                {renderItem ? renderItem(item) : item.label}
            </li>
          ))  
        }
      </ul>
  </div>
  );
}

export default Input;