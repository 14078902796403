import {normalizeError} from "../libs/mk-womanizer/error";
import {toast} from "react-toastify";

let statusCodeMapping = {
    // 409 : 'Conflit, une entrée similaire existe déjà',
    // 400 : 'Certains champs sont invalides',
    // 400 : 'Une erreur est survenue, essayez de rafraîchir la page',
    // 401 : 'Identifiants incorrects',
    // 403 : 'Vous n\'êtes pas autorisé à executer cette action',
    415 : 'Type de fichier non supporté',
    413 : 'Fichier trop lourd',
    429 : `Trop d'actions dans un court laps de temps, veuillez patienter avant de recommencer.`,
    500 : 'Une erreur serveur est survenue, contactez un administrateur ou un membre de l\'équipe technique',
    501 : 'Cette fonctionnalité n\'est pas disponible',
    ERR_NETWORK : 'Nous rencontrons quelques difficultés merci de recommencer d\'ici quelques minutes '
};

let isToggleToast = true;

function errorNormalizer(error){
    let errorResponse = error.response || {};
    let responseData = errorResponse.data || {};
    let normalized = normalizeError({
        errors: responseData.errors,
        message: responseData.message || errorResponse.statusText,
        name: null,
        statusCode: responseData.statusCode || errorResponse.status,
        error: responseData.error || errorResponse.statusText,
    });
    if(!errorResponse.status && error.code === "ERR_NETWORK"){
        normalized = normalizeError({
            errors: {},
            message: error.message,
            name: error.name,
            statusCode: error.code,
            error: {},
        });
    }
    if(!errorResponse.status && error.code === "ECONNABORTED"){
        normalized = normalizeError({
            errors: {},
            message: error.message,
            name: error.name,
            statusCode: error.code,
            error: {},
        });
    }

    //todo: en discuter avec max mais quand l'api est down affiche un message et cancel toutes les autres requetes
    /*if(error.code === "ERR_NETWORK"){
        toast(statusCodeMapping[error.code],{
            autoClose: 10000,
            className: 'toast-background toast-error',
            bodyClassName: "toast-font toast-error",
            progressClassName: 'toast-loader toast-error'
        });
    }*/
    if(statusCodeMapping[normalized.statusCode] && isToggleToast){
        isToggleToast = false;
        toast(statusCodeMapping[normalized.statusCode],{
            autoClose: 10000,
            className: 'toast-background toast-error',
            bodyClassName: "toast-font toast-error",
            progressClassName: 'toast-loader toast-error'
        });
    }
    if(normalized.statusCode === "ERR_NETWORK"){
        if(window.location.pathname !== '/maintenance') sessionStorage.setItem('maintenance-backup-path', window.location.pathname);
        window.location.href = '/maintenance';
    }

    return {...error, response: normalized};

}

export function response(res) {
    return res.data;
}


export function responseError(error) {
    let normalizedError = errorNormalizer(error);
    return Promise.reject(normalizedError);
}

export function responseQueryError(error) {
    let normalizedError = errorNormalizer(error);
    if (error.config && normalizedError?.response?.statusCode === "ERR_NETWORK" && window.plausible) {
        const fullURL = new URL(error.config.url, error.config.baseURL);
        window.plausible('ERR_NETWORK', {props: {url: error.config.url, path : fullURL.pathname, search : fullURL.search, method: error.config.method}});
        console.log(`ERR_NETWORK ${error.config.method} ${error.config.url}`);
    }
    return Promise.reject(normalizedError.response);
}

const dataInterceptor = {
    response,
    responseError,
    responseQueryError
};

export default dataInterceptor;