import React, {useState} from 'react';
import './EmailUnconfirmed.scss';
import Container from '../UI/Container/Container';
import Button, {BUTTON_VARIANTS} from '../UI/Button/Button';
import {
    sendEnrollmentEmailCurrentUser,
    updateEmailCurrentUser,
    USER_CURRENT_KEY
} from "../../../api/public-voissa/users";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import _ from "lodash";
import {toastError} from "../../../libs/toast";
import {CURRENT_USER, MAIL} from "../../../const/toasts";
import logo_gmail from "../../../images/logo-gmail.png";
import logo_yaoo from "../../../images/logo-yahoo.png";
import logo_outlook from "../../../images/logo-outlook.png";
import logo_orange from "../../../images/logo-orange.png";
import logo_laposte from "../../../images/logo-laposte.png";
import Input from "../UI/Input/Input";
import Group from "../UI/Group/Group";
import Drawer from "../Drawer/Drawer";

function webmail(currentEmail) {
    if (currentEmail) {
        let domain = currentEmail.substring(currentEmail.indexOf("@") + 1, currentEmail.lastIndexOf("."));
        let webmail = {
            "gmail" : {
                image: logo_gmail,
                href: "https://www.gmail.com"
            },
            "yahoo" : {
                image: logo_yaoo,
                href: "https://mail.yahoo.com"
            },
            "hotmail" : {
                image: logo_outlook,
                href: "https://outlook.com"
            },
            "live" : {
                image: logo_outlook,
                href: "https://outlook.com"
            },
            "outlook" : {
                image: logo_outlook,
                href: "https://outlook.com"
            },
            "orange" : {
                image: logo_orange,
                href: "https://messagerie.orange.fr"
            },
            "wanadoo" : {
                image: logo_orange,
                href: "https://messagerie.orange.fr"
            },
            "laposte" : {
                image: logo_laposte,
                href: "https://www.laposte.net/accueil"
            }
        };
        return webmail[domain] || false;
    }
    return false
}


const EmailUnconfirmedForm = ({
          currentUser,
      }) => {
    const queryClient = useQueryClient();

    let initEmail = _.get(currentUser, 'email.address') || "";

    const [toggleUpdateEmail, setToggleUpdateEmail] = useState(false);
    const [isSendMail, setIsSendMail] = useState(false);
    const [isSendMailLoading, setIsSendMailLoading] = useState(false);
    const [currentEmail, setCurrentEmail] = useState(initEmail);


    const {
        mutate : mutateSendEnrollmentEmail,
    } = useMutation(() => sendEnrollmentEmailCurrentUser(),{
        onError: () =>{
            toastError(MAIL.ERROR.SEND_ENROLLMENT);
        }
    });

    const {
        mutate : mutateUpdateEmail,
        error : errorUpdateEmail,
        isError : isErrorUpdateEmail,
        isLoading : isLoadingUpdateEmail
    } = useMutation((data) => updateEmailCurrentUser(data),{
        onSuccess: async () =>{
            setIsSendMailLoading(false);
            await queryClient.invalidateQueries([USER_CURRENT_KEY]);
        },
        onError: () =>{
            toastError(CURRENT_USER.ERROR.UPDATE);
        }
    });


    function sendMail(){
        if(!isSendMail){
            mutateSendEnrollmentEmail();
            setIsSendMail(true);
            setIsSendMailLoading(true);
            setTimeout(() =>setIsSendMailLoading(false), 800);
        }

    }

    function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        mutateUpdateEmail({email: currentEmail});

    }

    let ctaEmail = webmail();

    return (
        <>
            <div className={"email-unconfirmed-infos"}>
                <div className={"email-unconfirmed-title"}>Bienvenue {currentUser?.username} 🎉</div>
            Vous devez confirmer votre inscription en cliquant sur le lien de vérification qui a été envoyé par email à
                <span className={"email-unconfirmed-infos-email"}>
                    {ctaEmail
                        ? (<a href={ctaEmail.href} target={"_blank"} rel="noopener noreferrer nofollow">{currentEmail || _.get(currentUser, "email.address")}</a>)
                        : ` ${currentEmail || _.get(currentUser, "email.address")}`}
                .</span>
                {ctaEmail
                    ? (
                    <a href={ctaEmail.href} target={"_blank"} rel="noopener noreferrer nofollow">
                       <img src={ctaEmail.image} alt={"Voir vos Emails"}/>
                    </a>
                    ) : null}
                    <p style={{marginTop: "12px"}}>Pas reçu d'e-mail ? Écrivez à <a href="mailto:assistance@voissa.com">assistance@voissa.com</a></p>
            </div>

            <form className={"email-unconfirmed-form"} onSubmit={(e) => handleSubmit(e)}>
                {toggleUpdateEmail ? (
                    <>
                        <Input type={"email"} required={true}
                               value={currentEmail}
                               label={"Votre nouvelle adresse email"}
                               onChange={(event) => setCurrentEmail(event.target.value)}
                               error={isErrorUpdateEmail ? errorUpdateEmail.message || "Votre adresse email n'est pas valide" : false}
                                placeholder={"Votre Adresse Email"}/>
                        <Group right style={{marginTop : "8px"}}>
                            <Button onClick={() => {setToggleUpdateEmail(false); setCurrentEmail(initEmail)}}>Annuler</Button>
                            <Button variant={BUTTON_VARIANTS.REVERSE} disabled={currentEmail?.trim()?.length === 0} type={"submit"} loading={isLoadingUpdateEmail}>Enregistrer</Button>
                        </Group>
                    </>
                ) : (
                    <>
                        <div className={"email-unconfirmed-form-email-received"}>
                            <Group right className={"email-unconfirmed-form-group"}>
                                <Button variant={BUTTON_VARIANTS.OUTLINE} loading={isSendMailLoading} disabled={isSendMail} onClick={() =>sendMail()}>{isSendMail ? "Lien envoyé ✔" : "Renvoyer le lien"}</Button>
                                <Button variant={BUTTON_VARIANTS.OUTLINE}  onClick={() => setToggleUpdateEmail(true)}>Modifier l'email</Button>
                            </Group>
                        </div>

                    </>
                )
                }
            </form>
        </>
    )
};

const EmailUnconfirmed2 = () => {
    const queryClient = useQueryClient();

    const currentUser = queryClient.getQueryData([USER_CURRENT_KEY]);
    const isCurrentUser = !!_.get(currentUser, '_id');


    if (!isCurrentUser) {
        return null;
    }


    return (
        <Container id="email-unconfirmed">
            <EmailUnconfirmedForm currentUser={currentUser}  />
        </Container>
    )
};

const EmailUnconfirmed = ({
                             toggle = false,
                             handleClose = () =>{},
                             isModal = false
                         }) => {
    return isModal ? (
        <Drawer
            small
            open={toggle}
            handleClose={handleClose}
            headContent="Confirmer votre email"
        >
            <EmailUnconfirmed2
                toggle={toggle}
                handleClose={handleClose}
            />
        </Drawer>
    ) : (
        <EmailUnconfirmed2
            toggle={toggle}
            handleClose={handleClose}
        />
    )
}

export default EmailUnconfirmed;